<template>
  <b-card 
    img-right 
    :title="t('Integrations')">
    
    <b-row>
      <b-col cols="10">
        <b-img src="/assets/images/integrations/ml-logo-integrations.svg" style="height: 48px; width: 196px;" thumbnail></b-img>
      </b-col>
      <b-col cols="2">
        <b-button v-if="!isFeatureMLEnabled"
          variant="primary"
          class="ml-1"
          @click="enableML('')">
          {{ t('Enable') }}
        </b-button>
      </b-col>
    </b-row>
    <b-card-text v-if="isFeatureMLEnabled">
      <b-row>
        <b-col cols="3">
          &nbsp;
        </b-col>
        <b-col cols="9">
          &nbsp;
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          {{ t('Full Name') }}
        </b-col>
        <b-col cols="9">
          ({{ featureMLObject.meta.alias || '' }}) - {{ featureMLObject.meta.fullName || '' }}
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          {{ t('Brand Name') }}
        </b-col>
        <b-col cols="9">
          {{ featureMLObject.meta.brandName || '' }}
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          {{ t('Email') }}
        </b-col>
        <b-col cols="9">
          {{ featureMLObject.meta.email || '' }}
        </b-col>
      </b-row>
    </b-card-text>
  </b-card>
</template>
<script>
  import {
    BCard, BCardText, BRow, BCol, BFormInput, BButtonGroup, BButton, BTable, BMedia, BAvatar, BLink, BImg,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, VBTooltip,
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import { ref, onUnmounted, getCurrentInstance } from '@vue/composition-api'
  import { useUtils as useI18nUtils } from '@core/libs/i18n'
  
  export default {
    components: {
      BCard,
      BCardText,
      BRow,
      BCol,
      BFormInput,
      BButtonGroup,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      BImg,
      BTooltip,
  
      vSelect,
    },
    directives: {
      'b-tooltip': VBTooltip,
    },
    mounted() {
      this.session         = JSON.parse(localStorage.getItem('session'))
      this.featureMLObject = this.session.businessFeatures.find((item) => item.package === 'fgFeatureMLInterop')
      
      this.$root.$on('session-settings-refresh',(message) => {
        this.session         = JSON.parse(localStorage.getItem('session'))
        this.featureMLObject = this.session.businessFeatures.find((item) => item.package === 'fgFeatureMLInterop')
      })
    },
    watch: {
    },
    computed: {
      isFeatureMLEnabled: {
        get() {
          return this.session && this.session.businessFeatures && this.session.businessFeatures.length > 0 && this.session.businessFeatures.find((item) => item.package === 'fgFeatureMLInterop') && this.session.businessFeatures.find((item) => item.package === 'fgFeatureMLInterop').meta ? true : false
        }
      }
    },
    props:{
      taxid: String,
      branchesListData: {
        type: Array,
        default: () => [],
      },
    },
    setup(props, { emit }) {
      /** lsandoval: 15/12/2023 We import the i18n utilities */
      const { t } = useI18nUtils()
      return {
        // i18n
        t
      }
    },
    data() {
      return {
        session: null,
        featureMLObject: {} 
      }
    },
    methods: {
      // ILL: 060423 para que la cadena de busqueda se transforme a mayusculas
      fmtUppercaseAll(value) {
          let v = value.toUpperCase()        
          return v
      },
      enableML() {
        var url = 'https://auth.mercadolibre.com.mx/authorization?response_type=code&client_id=7349199143730967&redirect_uri=https://app.alpha.factura.green/fg/in/ml/auth/callback'
        window.open(url, "_blank", "toolbar=no,scrollbars=yes,resizable=yes");
      },
    }
  }
</script>
  
<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>
  
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>  